import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import EventWithUs from "./EventWithUs";
import Divider from "../common/Divider";
import axios from "axios";
import { initializeTagManager } from "../../TagManager";
import { useLocation } from "react-router-dom";
import { GeoAlt, Calendar, Alarm, TicketPerforated } from "react-bootstrap-icons";
import Degree45 from "../../gallery/icons/45degree.png";
import EventSimilar from "./EventSimilar";
import EventTicket from "./EventTicket";
import { useDispatch, useSelector } from "react-redux";
import { updateEvent, resetEvent } from "../../app/features/event/eventSlice";
import EventLoginModal from "./EventLoginModal";
import { BeatLoader } from "react-spinners";
import { setActiveLink } from "../../app/features/nav/navSlice";
import LeftCarouselArrow from "../../gallery/icons/leftCarouselArrow.png";
import RightCarouselArrow from "../../gallery/icons/rightCarouselArrow.png";
import ArrowLeft from "../../gallery/icons/arrowLeft.png";
import ArrowRight from "../../gallery/icons/arrowRight.png";

function EventDetail(props) {
  const cart = useSelector((state) => state.event.cart);
  const currentEvent = useSelector((state) => state.event.currentEvent);

  const dispatch = useDispatch();
  const location = useLocation();
  const substrings = location.pathname.split("/");
  const type = substrings["1"];
  const slug = substrings["2"];

  const [data, setData] = useState();

  useEffect(() => {
    dispatch(setActiveLink("event"));
    dispatch(resetEvent());
    initializeTagManager();
    fetchData();
  }, [type, slug]);

  async function fetchData() {
    await axios
      .request({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/api/${type}/${slug}`,
        headers: {
          Authorization: process.env.REACT_APP_API_KEY,
        },
      })
      .then(function (response) {
        setData(response.data.data);
        dispatch(updateEvent(response.data.data));
      })
      .catch(function (error) {
        console.error(error);
      });
  }
  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const handleCopy = (event) => {
    event.preventDefault();
    const currentUrl = window.location.href;
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(currentUrl)
        .then(() => {
          console.log("URL copied to clipboard!");
        })
        .catch((error) => {
          console.error("Failed to copy URL: ", error);
        });
    } else {
      fallbackCopyURL(currentUrl);
    }
    var myButton = document.getElementById("copylinkbtn");
    myButton.className = "btn-copy-pink btn-detail-light";
    myButton.innerHTML = `<img src=${Degree45}  alt=""/> Copied`;
    myButton.setAttribute("disabled", true);

    setTimeout(function () {
      myButton.className = "btn-copy-pink";
      myButton.innerHTML = `<img src=${Degree45}  alt=""/> Copy link to this page`;
      myButton.removeAttribute("disabled");
    }, 5000);
  };
  const fallbackCopyURL = (url) => {
    const textArea = document.createElement("textarea");
    textArea.value = url;
    textArea.style.position = "fixed"; // Make it invisible
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      console.log("URL copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy URL: ", error);
    }
    document.body.removeChild(textArea);
  };
  const dateFormat = (d, type = 0) => {
    let date = new Date(d);

    const dayOfWeek = date.toLocaleString("en-UK", { weekday: "short" });
    var month = date.toLocaleString("en-UK", { month: "short" });
    const year = date.getFullYear();
    var monthCmp = date.toLocaleString("en-UK", { month: "long" });
    const day = date.getDate();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = "";
    if (type === 1) {
      formattedDate = `${dayOfWeek}, ${day} ${month} ${year}, ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;
    } else if (type === 2) {
      formattedDate = `${hours}:${minutes < 10 ? "0" + minutes : minutes}${ampm}`;
    } else if (type === 3) {
      formattedDate = `${day} ${month}`;
    } else if (type === 4) {
      formattedDate = `${day} ${monthCmp} ${date.getFullYear()}`;
    } else {
      formattedDate = `${dayOfWeek}, ${day} ${month}, ${hours}:${minutes < 10 ? "0" + minutes : minutes}${ampm}`;
    }

    return formattedDate;
  };

  return (
    <>
      <Helmet>
        {currentEvent.tickets && data && <title>{`Sahi Search - ${props.pageTitle} - ${data.name}`}</title>}

        <meta name="description" content="" />
      </Helmet>
      {!data && (
        <div className="loader-in-component-body">
          <BeatLoader color="#C6153B" onLoad={goToTop} />
        </div>
      )}
      {data && (
        <>
          {currentEvent.tickets && data && data.ticket_start_at && new Date(data.ticket_start_at).getTime() >= new Date().getTime() && (
            <div class="alert alert-warning d-flex justify-content-center" role="alert">
              <div style={{ fontWeight: "600", fontSize: "22px" }}>
                This is an upcoming event. Tickets will be <strong>available from {dateFormat(data.ticket_start_at, 4)}.</strong> Follow us on{" "}
                <a href="https://www.facebook.com/sahisearch" target="_blank" rel="noopener noreferrer" className="text-decoration-underline text-black-50">
                  Facebook
                </a>{" "}
                or{" "}
                <a href="https://www.instagram.com/sahisearch" target="_blank" rel="noopener noreferrer" className="text-decoration-underline text-black-50">
                  Instagram
                </a>{" "}
                for updates.
              </div>
            </div>
          )}
          <div className="container">
            <div className="inner-content">
              <div className="row bread-and-sort">
                {!currentEvent.tickets ? (
                  <div className="loader-in-component-body">
                    <BeatLoader color="#C6153B" onLoad={goToTop} />
                  </div>
                ) : (
                  <>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                      {data && data.gallery.length > 0 && (
                        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
                          <div className="carousel-inner carousel-inner-height align-content-center">
                            {data.gallery.map((element, index) => {
                              var customActive = "";
                              if (index === 0) {
                                customActive = "active";
                              }
                              return (
                                <div
                                  style={{
                                    position: "relative", // Required for child elements positioning
                                    width: "100%", // Adjust the width as needed
                                    height: "100%",
                                    zIndex: "1",
                                    textAlign: "center",
                                    overflow: "hidden",
                                  }}
                                  className={`carousel-item ${customActive}`}
                                  key={`carousel_${index}`}
                                  data-bs-slide-to={index}
                                >
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      backgroundImage: `url(${element.image_path}/${element.image_name})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      filter: "blur(50px)", // Adjust the blur value as needed
                                      zIndex: "-1",
                                    }}
                                  />
                                  <img className="d-block carousel-img-height img-fluid" alt="..." src={`${element.image_path}/${element.image_name}`} />
                                </div>
                              );
                            })}
                          </div>
                          {data.gallery.length > 1 && (
                            <div className="carousel-nav hidden-xs">
                              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                <span aria-hidden="true">
                                  <img src={LeftCarouselArrow} alt="" />
                                </span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                <span aria-hidden="true">
                                  <img src={RightCarouselArrow} alt="" />
                                </span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          )}
                          {data.gallery.length > 1 && (
                            <div className="carousel-nav hidden-md">
                              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                <span aria-hidden="true">
                                  <img src={ArrowLeft} alt="" />
                                </span>
                                <span className="visually-hidden">Previous</span>
                              </button>
                              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                <span aria-hidden="true">
                                  <img src={ArrowRight} alt="" />
                                </span>
                                <span className="visually-hidden">Next</span>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                      {data && data.gallery.length === 0 && (
                        <div id="carouselExampleCaptions" className="carousel slide">
                          <div className="carousel-inner carousel-inner-height align-content-center">
                            <div
                              className={`carousel-item active`}
                              style={{
                                position: "relative", // Required for child elements positioning
                                width: "100%", // Adjust the width as needed
                                height: "100%",
                                zIndex: "1",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url(${data.image_path})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  filter: "blur(50px)", // Adjust the blur value as needed
                                  zIndex: "-1",
                                }}
                              />
                              <img className="d-block carousel-img-height img-fluid" alt="..." src={data.image_path} />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="hidden-xs">
                        <h6 className="detail-heading">Event detail</h6>
                        <p
                          className="detail-desc"
                          dangerouslySetInnerHTML={{
                            __html: data ? (data.detail ? data.detail.replace(/\n/g, "<br />") : "") : "",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12">
                      <h3 className="detail-event-title">{data ? (data.name ? data.name : "") : ""}</h3>
                      <p>
                        <button id="copylinkbtn" className="btn-copy-pink" onClick={handleCopy}>
                          <img src={Degree45} alt={""} />
                          &nbsp;Copy link to this page
                        </button>
                      </p>
                      <p className="location-on-detail paragraph-margin-sm">
                        <GeoAlt size={13} color="#4E5BA6" />
                        &nbsp;
                        {data.address1 ? `${data.address1}, ` : ""}
                        {data ? (data.location ? data.location : "") : ""} {data ? (data.postal_code ? ` - ${data.postal_code}` : "") : ""}
                      </p>
                      {parseInt(data.show_start_date) === parseInt(1) && (
                        <p className="location-on-detail me-1">
                          <Calendar size={13} color="#4E5BA6" className="me-1" />
                          {dateFormat(data.start_date, 1)}
                          {/*  -{" "}
                          {dateFormat(data.end_date)} */}
                        </p>
                      )}
                      {data.last_entry_time && parseInt(data.show_start_date) === parseInt(1) && (
                        <p className="location-on-detail me-1">
                          
                          <Alarm size={13} color="#4E5BA6" className="me-1" />
                          Doors close at {dateFormat(data.last_entry_time, 1)}
                        </p>
                      )}
                      <p className="paragraph-margin-sm">
                        <span className="badge rounded-pill card-business">{data ? data.category : ""}</span>
                        {data && data.adult_event && <span className="badge rounded-pill for-adults">18+ only</span>}
                        {new Date(data.ticket_start_at).getTime() >= new Date().getTime() && <span className="badge rounded-pill for-adults">Coming soon</span>}
                        {new Date(data.end_date).getTime() <= new Date().getTime() && <span className="badge rounded-pill for-adults">Expired</span>}
                      </p>
                      <hr className="detail-custom-border" />

                      <div className="hidden-md hidden-sm">
                        <h6 className="detail-heading">{data ? (data.name ? data.name : "") : ""}</h6>
                        <p
                          className="detail-desc"
                          dangerouslySetInnerHTML={{
                            __html: data ? (data.detail ? data.detail.replace(/\n/g, "<br />") : "") : "",
                          }}
                        />
                      </div>
                      {currentEvent.tickets &&  new Date(data.end_date).getTime() >= new Date().getTime() && (
                        <div className="event-tickets-container">
                          {currentEvent.tickets.map((element, index) => {
                            const today = new Date();
                            const ticketExpiry = new Date(element.expiry_date);
                            let disable = null;
                            //bellow is the check to disable tickets if all tickets are sold, ticket expire or event cooming soon
                            if (parseInt(element.quantity) - parseInt(element.sold) <= 0 || ticketExpiry.getTime() < today.getTime() || new Date(data.ticket_start_at).getTime() >= new Date().getTime()) {
                              disable = "card ticket-card ticket-card-disable";
                            }
                            return <EventTicket key={index} ticket={element} startDate={data.start_date} disable={disable} />;
                          })}
                          {cart.productsCount > 0 && (
                            <>
                              <div className="ticket-booked-info row text-center">
                                <div className="col-12 ticket-info-count">
                                  {cart.productsCount}&nbsp;
                                  {cart.productsCount === 1 ? "ticket" : "tickets"}
                                </div>
                                <div className="col-12">
                                  <h3 className="remaining-time" id="detailPageTime">
                                    Time Remaining :{" "}
                                  </h3>
                                </div>
                                <div className="col-12 ticket-info-inclusive">All ticket prices are inclusive of booking fees</div>
                                <div className="col-12 ticket-info-grand-total">£{cart.subTotal.toFixed(2)}</div>
                              </div>
                              <div
                                className="row"
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "24px",
                                }}
                              >
                                <div className="col-12">
                                  <ul className="ticket-info-list">
                                    <li>These electronic tickets will be delivered to your email.</li>
                                    {/* <li>
                                        You can return them before{" "}
                                        {dateFormat(data.start_date, 3)}.
                                      </li> */}
                                    <li>If the event gets cancelled, you will receive a refund within 30 days.</li>
                                  </ul>
                                </div>
                                {/* <div className="col-12 ticket-info-learn-more">
                                    <a href="/">
                                      Learn more about e-tickets here
                                    </a>
                                  </div> */}
                              </div>
                            </>
                          )}
                          <div className="row">
                            <div className="col-12">
                              {cart.productsCount > 0 && (
                                <>
                                  <button className="btn btn-ticket-reserve" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-bs-dismiss="modal">
                                    <TicketPerforated size={25} color="#ffffff" />
                                    &nbsp;&nbsp;Reserve tickets
                                  </button>
                                  <EventLoginModal />
                                </>
                              )}
                              {cart.productsCount === 0 && (
                                <button className="btn btn-disabled-ticket-reserve">
                                  <TicketPerforated size={25} color="#ffffff" />
                                  &nbsp;&nbsp;Reserve tickets
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <EventSimilar item={data.id} page={data.page} category={data.category_id} location={data.location_id} />
          <Divider />
          <EventWithUs />
        </>
      )}
    </>
  );
}

export default EventDetail;
